document.addEventListener('DOMContentLoaded', () => {
  const inputs = [
    document.getElementById('vin-lot-search'),
    document.getElementById('vin-search-header')
  ];

  const suggestionsBoxes = [
    document.getElementById('suggestions-box'),
    document.getElementById('suggestions-mobile-box')
  ];

  suggestionsBoxes.forEach(box => box ? box.style.display = 'none' : null);

  inputs.forEach((input, index) => {
    if (!input) return;

    const suggestionsBox = suggestionsBoxes[index];

    input.addEventListener('input', () => {
      const query = input.value.trim();

      if (query.length < 3) {
        if (suggestionsBox) {
          suggestionsBox.innerHTML = '';
          suggestionsBox.style.display = 'none';
        }
        return;
      }

      fetch(`/search?query=${encodeURIComponent(query)}`)
        .then(response => response.json())
        .then(data => {
          if (!suggestionsBox) return;

          suggestionsBox.innerHTML = '';

          if (data.suggestions) {
            let hasSuggestions = false;

            if (Array.isArray(data.suggestions.brands)) {
              data.suggestions.brands.forEach(suggestion => {
                const option = document.createElement('div');
                option.classList.add('suggestion-option');
                option.textContent = suggestion.name;
                option.addEventListener('click', () => {
                  input.value = suggestion.name;
                  suggestionsBox.innerHTML = '';
                  suggestionsBox.style.display = 'none';
                });
                suggestionsBox.appendChild(option);
                hasSuggestions = true;
              });
            }

            if (Array.isArray(data.suggestions.models)) {
              data.suggestions.models.forEach(suggestion => {
                const option = document.createElement('div');
                option.classList.add('suggestion-option');
                option.textContent = suggestion.name;
                option.addEventListener('click', () => {
                  input.value = suggestion.name;
                  suggestionsBox.innerHTML = '';
                  suggestionsBox.style.display = 'none';
                });
                suggestionsBox.appendChild(option);
                hasSuggestions = true;
              });
            }

            suggestionsBox.style.display = hasSuggestions ? 'block' : 'none';
          }
        })
        .catch(error => {
          console.error('Error:', error);
        });
    });
  });

  document.addEventListener('click', (e) => {
    inputs.forEach((input, index) => {
      const suggestionsBox = suggestionsBoxes[index];
      if (suggestionsBox && !input.contains(e.target) && !suggestionsBox.contains(e.target)) {
        suggestionsBox.innerHTML = '';
        suggestionsBox.style.display = 'none';
      }
    });
  });
});
