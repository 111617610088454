document.addEventListener('DOMContentLoaded', function () {
  const stages = document.querySelectorAll('.stage');
  const progressRange = document.querySelector('.progress-range');
  let windowHeight = window.innerHeight;

  function updateProgress(index) {
    if (window.innerWidth > 1200) {
      const width = (index + 1) * (100 / stages.length) - 3.5;
      progressRange.style.width = `${width}%`;
    } else {
      const height = (index + 1) * (100 / stages.length) - 3.5;
      progressRange.style.height = `${height}%`;
    }
  }

  function updateColors(currentIndex) {
    stages.forEach((stage, index) => {
      const color = index <= currentIndex ? '#CED1D3' : '#46484C';
      stage.querySelector('.stage-value .h5-medium').style.color = color;
      stage.querySelector('.stage-value .main-text-medium').style.color = color;
    });
  }

  function setActiveStageOnScroll() {
    const middleOfScreen = window.scrollY + windowHeight / 2;

    stages.forEach((stage, index) => {
      const stageTop = stage.offsetTop;
      const stageBottom = stageTop + stage.clientHeight;

      if (middleOfScreen >= stageTop && middleOfScreen <= stageBottom) {
        stages.forEach(s => s.classList.remove('active'));
        stage.classList.add('active');

        updateProgress(index);
        updateColors(index);
      }
    });
  }

  function setActiveStageOnHover() {
    stages.forEach((stage, index) => {
      stage.addEventListener('mouseenter', function () {
        stages.forEach(s => s.classList.remove('active'));
        stage.classList.add('active');

        updateProgress(index);
        updateColors(index);
      });
    });
  }

  if (stages.length > 0) {
    setActiveStageOnHover();
    window.addEventListener('scroll', setActiveStageOnScroll);
    window.addEventListener('resize', () => {
      windowHeight = window.innerHeight;
      setActiveStageOnScroll();
    });
  }
});
